import React from "react";
import { Card, Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { UserOutlined, BankOutlined } from "@ant-design/icons";
import EmployeeInformation from "./EmployeeInformation";
import BankInformation from "./BankInformation";
import Documents from "./Documents";
import Employment from "./Employment";
import References from "./References";
import StatusLogs from "./StatusLogs";
import ActivityStream from "./ActivityStream";
import ExistingLoans from "./ExistingLoans";
import EMIEnachSingature from "./EMIEnachSingature";
import AdditionalInfo from "./AdditionalInfo";
import EMIEnachEWA from "./EMIEnachEWA";


const UpdateDetailTabs: React.FC = () => {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");

  let tabList = [
    {
      key: "1",
      children: <EmployeeInformation ></EmployeeInformation>,
      label: (
        <div className="tab-list">
          <UserOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Employee Information</div>
            <div className="tab-detail">personal & general details</div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      children: <BankInformation />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Bank & Financial</div>
            <div className="tab-detail">bank details</div>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      children: <Documents />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Documents</div>
            <div className="tab-detail">review documents</div>
          </div>
        </div>
      ),
    },
    {
      key: "4",
      children: <Employment />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Employment</div>
            <div className="tab-detail">employment details</div>
          </div>
        </div>
      ),
    },
    {
      key: "5",
      children: <References />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">References</div>
            <div className="tab-detail">your references</div>
          </div>
        </div>
      ),
    },
    {
      key: "6",
      children: <StatusLogs />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Status Logs</div>
            <div className="tab-detail">Status Logs</div>
          </div>
        </div>
      ),
    },
    {
      key: "7",
      children: <ExistingLoans />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Existing Loans</div>
            <div className="tab-detail">Status Logs</div>
          </div>
        </div>
      ),
    },
    {
      key: "8",
      children: <EMIEnachSingature />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">EMI Enach Signature</div>
            <div className="tab-detail">EMI Enach Signature</div>
          </div>

        </div>
      ),
    },
    {
      key: "9",
      children: <AdditionalInfo />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Additional Info</div>
            <div className="tab-detail">Additional Info</div>
          </div>

        </div>
      ),
    },
    {
      key: "10",
      children: <ActivityStream />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">Activity Stream</div>
            <div className="tab-detail">(Betax Version)</div>
          </div>
        </div>
      ),
    },
  ];
  if (type == 'drawdown' || type == "onboarding") {         // for drawdonw type
    tabList = [tabList[0], tabList[2], tabList[3], tabList[4], tabList[8],
    {
      key: "8",
      children: <EMIEnachEWA />,
      label: (
        <div className="tab-list">
          <BankOutlined className="img02" />
          <div className="right01">
            <div className="tab-name">EMI Enach Signature</div>
            <div className="tab-detail">EMI Enach Signature</div>
          </div>

        </div>
      ),
    },
    ];
  }
  return (
    <>
      <Card bordered={false} className="tabs-card">
        <Tabs
          tabPosition={"left"}
          className="tab-main"
          defaultActiveKey="1"
          items={tabList.map(item => {
            if (type === "operations" && item.key === "8") {
              return {
                label: null,
                key: '',
                children: null,
              }; // Return null to skip rendering the tab
            }

            return {
              label: item.label,
              key: item.key,
              children: item.children,
            };
          }).filter(Boolean)}
        />
      </Card>
    </>
  );
};
export default UpdateDetailTabs;
