import { Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";

export function ShowDetailsIconDrawdown(props: any) {
  const history = useHistory();
  const redirect_url = window.location.pathname.includes('ewaOnboarding') ? `/drawdown-detail?onboarding_id=${props["data"]["onboarding_id"]}&employee_id=${props["data"]["internal_emp_id"]}&type=onboarding`
   : window.location.pathname.includes('ewaDrawdown') ? `/drawdown-detail?onboarding_id=${props["data"]["onboarding_id"]}&employee_id=${props["data"]["internal_emp_id"]}&type=drawdown&drawdown_id=${props["data"]["drawdown_id"]}` : '';
  const redirectToEmployeeDetails = () => {
    history.push(
      redirect_url
    );
  };
  return (
    <Tooltip title={"Click here to go to details"}>
      <Button
        type="text"
        icon={<EyeOutlined />}
        onClick={redirectToEmployeeDetails}
      />
    </Tooltip>
  );
}
