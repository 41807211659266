import { useLayoutEffect, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Pagination, Tooltip, Button, Select, Drawer, message, DatePicker, Input, PaginationProps, Spin } from "antd";
import AgGridComponent from "./common/AgGridComponent";
import {
  setAppraisalApiParams,
  getAppraisalList,
  reset,
  fetchNbfc,
  getDrawdownList,
  setDrawdownApiParams,
} from "../state/action-creators";
import {util} from "../utitlities/util"
import { BtnCellRenderer } from "./button";
import { ShowDetailsIconAppraisal } from "./showDetailsIconAppraisal";
import { Employee } from "./icons/employee";
import { DocumentsVerified } from "./icons/documentsVerified";
import { EnachGenerated } from "./icons/enachgenerated";
import { Esign } from "./icons/esign";
import { DuplicateLoan } from "./icons/duplicateloan";
import { ActiveLoan } from "./icons/activeloan";
import { LoanClosed } from "./icons/loanClosed";
import { Enach } from "./icons/enach";
import { Activerequest } from "./icons/activerequests";
import { getUsers } from "../utitlities/request";
import { appraisalApiParamsInitState } from "../state/reducers/appraisalReducer";
import { DynamicMandateSignature } from "../utitlities/filteroptions";
import { bank_status, client_name, esanction_status } from "../utitlities/status";
import { drawdownApiParamsInitState } from "../state/reducers/drawdownReducer";
import { ShowDetailsIconDrawdown } from "./showDetailsIconDrawdown";

let setTimeoutId: any;

export default function Drawdown() {

  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const gridApiRef = useRef<any>(null);
  const [height, setHeight] = useState(window.innerHeight);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");

  const filterOptionsBankStatus: any = DynamicMandateSignature(bank_status);
  const filterOptionsClientName: any = DynamicMandateSignature(client_name);
  const filterOptionsEsanction: any = DynamicMandateSignature(esanction_status);

  const [pageSize, setPageSize] = useState(
    drawdownApiParamsInitState?.pageSize || 50
  );
  const appraisalApiLoader = useSelector(
    (state: any) => state?.appraisal?.appraisalApiResponse?.loading
  );
  const drawdownApiLoader = useSelector(
    (state: any) => state?.drawdown?.drawdownApiResponse?.loading
  );
  const defaultColDef = {
    flex: 1,
    resizable: true,
    // autoHeight: true,
    // wrapText: true,
    // sortable: true,
    // filter: true,
  };
  const appraisalApiParams = useSelector(
    (state: any) => state?.appraisal?.appraisalApiParams
  );
  const drawdownApiParams = useSelector(
    (state: any) => state?.drawdown?.drawdownApiParams
  );
  const appraisalList = useSelector(
    (state: any) => state?.appraisal?.appraisalApiResponse?.response
  );
  const drawdownList = useSelector(
    (state: any) => state?.drawdown?.drawdownApiResponse?.response
  );
  const filterData = drawdownApiParams?.filterData;
  const filterModel = drawdownApiParams?.filterModel;
  const sortModel = drawdownApiParams?.sortModel;

  useEffect(() => {
    async function fetchUserApi() {
      const users = await getUsers();
      setUsers(users.filter((user: any) => {
        return user.role !== "deactivated"
      }));
    }
    fetchUserApi();
    dispatch(reset());
    dispatch(fetchNbfc());
    const user = JSON.parse(localStorage?.getItem("user") || "");
    setRole(user?.role);
    // setStates(AppraisalStatus);
    const _drawdownApiParams = localStorage?.getItem("DRAWDOWN_API_PARAMS")
      ? // @ts-ignore
      JSON.parse(localStorage?.getItem("DRAWDOWN_API_PARAMS"))
      : "";

    if (_drawdownApiParams) {
      dispatch(
        setDrawdownApiParams({
          ..._drawdownApiParams,
          page: 1,
        })
      );
      setPageSize(_drawdownApiParams?.pageSize);
      gridApiRef?.current?.setFilterModel(_drawdownApiParams?.filterModel);
      gridApiRef?.current?.setSortModel(_drawdownApiParams?.sortModel);
    }
  }, [dispatch]);

  useEffect(() => {
    if (gridApiRef?.current) {
      if (drawdownApiLoader) {
        gridApiRef?.current.showLoadingOverlay();
      } else {
        gridApiRef?.current.hideOverlay();
      }
    }
  }, [drawdownApiLoader]);

  useEffect(() => {
    if (setTimeoutId) clearTimeout(setTimeoutId);
    setTimeoutId = setTimeout(() => {
      localStorage.setItem(
        "DRAWDOWN_API_PARAMS",
        JSON.stringify(drawdownApiParams)
      );
      let request = {
        page: drawdownApiParams?.page,
        limit: drawdownApiParams?.pageSize,
        ...filterData,
        userid: "all",
      };
      for (const [key, value] of Object.entries(filterModel || {})) {
        let _value;
        // @ts-ignore
        if (value?.filterType === "date") {
          // @ts-ignore
          _value = value?.dateFrom;
        } else if (
          key === "nbfc_status" ||
          key === "is_bank_account_verified" ||
          key === "avg_balance" ||
          key === "user_name" ||
          key === "esign_status" ||
          key === "enach_status" ||
          key === "sanction_status" ||
          key === "client_name" ||
          key === "ops_username"
        ) {
          // @ts-ignore
          _value = value?.type;
        } else {
          // @ts-ignore
          _value = value?.filter;
        }
        // @ts-ignore
        request[key] = _value;
      }
      for (const _sort of sortModel || []) {
        // @ts-ignore
        request["sort_key"] = _sort?.colId;
        // @ts-ignore
        request["sort_order"] = _sort?.sort;
      }
      if (!request?.status) {
        request["status"] = ["initiated"]//[...AppraisalActualStatus];
      }
      dispatch(getDrawdownList(request));
    }, 500);
  }, [drawdownApiParams, dispatch, filterData, filterModel, sortModel]);

  const handleFilterChanged = () => {
    const filterModel = gridApiRef?.current?.getFilterModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setDrawdownApiParams({
        ...drawdownApiParams,
        filterModel,
      })
    );
    setSelectedRows([]);
  };
  const handleSortChanged = (event: any) => {
    const sortModel = gridApiRef?.current?.getSortModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setDrawdownApiParams({
        ...drawdownApiParams,
        sortModel,
      })
    );
    setSelectedRows([]);
  };
  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
  };
  const handleRowDataChanged = (event: any) => {
    setTimeout(() => {
      gridApiRef?.current?.setFilterModel(filterModel);
      gridApiRef?.current?.setSortModel(sortModel);
    }, 0);
  };
  const onRowSelectedHandle = (event: any) => {
    const isSelected = !!event?.node?.selected;
    let selectedRowData = event?.data;
    if (isSelected && event?.data) {
      // @ts-ignore
      setSelectedRows((current: Array) => [...current, selectedRowData]);
    } else {
      // @ts-ignore
      setSelectedRows((current: Array) => [
        ...current?.filter((obj: any) => {
          return obj?.borrower_id !== event?.data?.borrower_id;
        }),
      ]);
    }
  };
  const onChangePage = (page: number, pageSize?: number) => {
    if (page && pageSize) {
      dispatch(
        setDrawdownApiParams({
          ...drawdownApiParams,
          page,
          pageSize,
        })
      );
    }
  };
  function updateDimension() {
    setHeight(window.innerHeight);
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);
  const columnDefs = [
    {
      field: "ops_id",
      headerName: "EWA ID",
      pinned: "left",
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      minWidth: 140,
      suppressSizeToFit: false,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "pan",
      headerName: "PAN",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    // {
    //   field: `${first_name} ${last_name}`,
    //   headerName: "Full Name",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     trimInput: true,
    //     maxNumConditions: 1,
    //   },
    //   pinned: "left",
    //   width: 140,
    //   minWidth: 140,
    //   maxWidth: 140,
    //   suppressSizeToFit: true,
    //   cellStyle: { fontSize: "12px" },
    // },
    {
      field: "contact_no",
      headerName: "Mobile",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
      suppressSizeToFit: true,
      width: 120,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      field: "whatsapp_no",
      headerName: "Whatsapp",
      cellStyle: { fontSize: "12px" },
      suppressSizeToFit: true,
      width: 120,
      minWidth: 120,
      maxWidth: 120,
    },
    // {
    //   headerName: "Requested Amount",
    //   field: "requested_amount",
    //   width: 140,
    //   minWidth: 140,
    //   maxWidth: 140,
    //   suppressSizeToFit: true,
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     trimInput: true,
    //     maxNumConditions: 1,
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   headerName: "Request Id",
    //   field: "request_ops_id",
    //   width: 140,
    //   minWidth: 140,
    //   maxWidth: 140,
    //   suppressSizeToFit: true,
    //   cellStyle: { fontSize: "12px" },
    //   sortable: false,
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     trimInput: true,
    //     maxNumConditions: 1,
    //   },
    // },
    // {
    //   headerName: "Suggested Amount",
    //   field: "suggested_amount_requested",
    //   width: 180,
    //   minWidth: 180,
    //   maxWidth: 180,
    //   suppressSizeToFit: true,
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     trimInput: true,
    //     maxNumConditions: 1,
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   headerName: "Third Party Status",
    //   field: "third_party_status",
    //   width: 180,
    //   minWidth: 180,
    //   maxWidth: 180,
    //   suppressSizeToFit: true,
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     trimInput: true,
    //     maxNumConditions: 1,
    //   },
    //   cellStyle: { fontSize: "12px" },
    //   valueFormatter: (params: any) => (params.value ? "Yes" : "No"),
    // },
    {
      field: "drawdown_status",
      headerName: "Drawdown Status",
      minWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "onboarding_status",
      headerName: "Onboarding Status",
      minWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    // {
    //   field: "nbfc_status",
    //   headerName: "Nbfc Status",
    //   minWidth: 160,
    //   filter: true,
    //   valueGetter: getNbfcStatus,
    //   filterParams: {
    //     filterOptions: filterOptionsNbfcStatus,
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     trimInput: true,
    //     maxNumConditions: 1,
    //   },
    //   suppressSizeToFit: true,
    //   cellStyle: { fontSize: "12px" },
    // },

    {
      field: "is_bank_account_verified",
      headerName: "Bank Status",
      minWidth: 160,
      filter: true,
      valueGetter: util.getBankStatus,
      filterParams: {
        filterOptions: filterOptionsBankStatus,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        trimInput: true,
        maxNumConditions: 1,
      },
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Client Name",
      field: "company_name",
      minWidth: 160,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterOptionsClientName,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
    },
    // {
    //   field: "request_status_comment",
    //   headerName: "Loan Status Comment",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    //   minWidth: 180,
    //   suppressSizeToFit: true,
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "ops_request_status_comment",
    //   headerName: "Request Status Comment",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    //   minWidth: 200,
    //   suppressSizeToFit: true,
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   headerName: "Approved Amount",
    //   field: "approved_amount",
    //   minWidth: 161,
    //   suppressSizeToFit: true,
    //   cellStyle: { fontSize: "12px" },
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    //   sortable: false,
    // },
    // {
    //   field: "user_name",
    //   headerName: "Loan Assigned To",
    //   valueGetter: AssignedTo,
    //   pinned: "right",
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   minWidth: 150,
    //   maxWidth: 150,
    //   filterFramework: CustomDropdownFilter,
    //   filterParams: {
    //     options: filterOptionsAssigned,
    //     buttons: ["apply", "reset", "cancel"],
    //     type: "loan",
    //     closeOnApply: true,
    //     trimInput: true,
    //   },
    // },
    {
      field: "onboarding_id",
      headerName: "Details",
      pinned: "right",
      hide: role === "operations" ? true : false,
      suppressSizeToFit: true,
      cellRenderer: "ShowDetailsIconDrawdown",
      cellStyle: { fontSize: "12px" },
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      field: "nbfc_name",
      headerName: "NBFC",
      sortable: false,
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
      width: 140,
      maxWidth: 140,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
    },
    // {
    //   field: "esign_status",
    //   headerName: "E-Signature",
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 140,
    //   width: 140,
    //   maxWidth: 140,
    //   filterParams: {
    //     filterOptions: filterOptionsEsign,
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    //   filter: true,
    // },
    // {
    //   field: "enach_status",
    //   headerName: "E-Nach",
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 140,
    //   width: 140,
    //   maxWidth: 140,
    //   filterParams: {
    //     filterOptions: filterOptionsEnach,
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    //   filter: true,
    // },
    {
      field: "sanction_status",
      headerName: "E-Sanction",
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
      width: 140,
      maxWidth: 140,
      filterParams: {
        filterOptions: filterOptionsEsanction,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        maxNumConditions: 1,
        trimInput: true,
      },
      filter: true,
    },
    // {
    //   field: "verified_ref_count",
    //   headerName: "Reference Verified",
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 160,
    //   filter: "agNumberColumnFilter",
    //   sortable: false,
    //   filterParams: {
    //     buttons: ["apply", "reset", "cancel"],
    //     closeOnApply: true,
    //     filterOptions: ["contains"],
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    // },
    // {
    //   headerName: "Disbursal Date",
    //   field: "disbursal_date",
    //   filterParams: dateFilterParams,
    //   filter: "agDateColumnFilter",
    //   valueGetter: parseDisbursalDate,
    //   sortable: false,
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 140,
    // },

    // {
    //   headerName: "Request Date",
    //   field: "request_created_date",
    //   filterParams: dateFilterParams,
    //   filter: "agDateColumnFilter",
    //   valueGetter: parseRequestDate,
    //   sortable: true,
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 140,
    // },
    // {
    //   headerName: "Status Updated",
    //   field: "status_last_updated_at",
    //   filterParams: dateFilterParams,
    //   filter: "agDateColumnFilter",
    //   valueGetter: parseupdatedDate,
    //   sortable: true,
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 140,
    // },
    // {
    //   field: "ops_username",
    //   headerName: "Request Assigned to ",
    //   valueGetter: AssignedToOps,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 170,
    //   filterFramework: CustomDropdownFilter,
    //   filterParams: {
    //     options: filterOptionsAssigned,
    //     buttons: ["apply", "reset", "cancel"],
    //     type: "request",
    //     closeOnApply: true,
    //     maxNumConditions: 1,
    //     trimInput: true,
    //   },
    // },
  ];
  const showTotal: PaginationProps["showTotal"] = (total) =>
    `Total Rows: ${total}`;

  return (
    <>
    <div style={{ backgroundColor: "#f0f0f0" }}>
    <Row>
          <Col span={24}>
            <div
              className="ag-theme-alpine"
              style={{
                height: height * 0.76,
                padding: "5px 15px 15px 15px",
                fontSize: "12px",
              }}
            >
              <AgGridComponent
                rowData={drawdownList?.result || []}
                onFilterChanged={handleFilterChanged}
                onSortChanged={handleSortChanged}
                onGridReady={onGridReady}
                onRowDataChanged={handleRowDataChanged}
                onRowSelected={onRowSelectedHandle}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                totalPages={Math.ceil(
                  Number(drawdownList?.count) / drawdownApiParams?.pageSize
                )}
                frameworkComponents={{
                  btnCellRenderer: BtnCellRenderer,
                  ShowDetailsIconAppraisal: ShowDetailsIconAppraisal,
                  ShowDetailsIconDrawdown: ShowDetailsIconDrawdown,
                  document: Document,
                  employee: Employee,
                  active_request: Activerequest,
                  enach: Enach,
                  document_verified: DocumentsVerified,
                  enach_generated: EnachGenerated,
                  esign: Esign,
                  duplicate_loan: DuplicateLoan,
                  active_loan: ActiveLoan,
                  is_loan_closed: LoanClosed,
                }}
              />
            </div>
            {drawdownList?.result?.length ? (
              <div
                style={{
                  display: "flex",
                  margin: "0px 15px 10px 15px",
                }}
              >
                {selectedRows?.length ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000",
                    }}
                  >
                    Selected Rows: {selectedRows?.length || 0}
                  </div>
                ) : null}
                <Pagination
                  style={{
                    justifyContent: "right",
                    textAlign: "right",
                    marginLeft: "auto",
                  }}
                  current={drawdownApiParams?.page}
                  onChange={onChangePage}
                  total={Number(appraisalList?.count || 0)}
                  pageSize={drawdownApiParams?.pageSize}
                  defaultPageSize={drawdownApiParams?.page}
                  showSizeChanger={false}
                  showTotal={showTotal}
                />
              </div>
            ) : null}
          </Col>
        </Row>
    </div>
    </>
    // ... same JSX structure as OpsReview
  );
} 