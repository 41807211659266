import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Select, Tag, Divider, Button, Modal, Input, Typography, message, Table, Spin } from "antd";
import type { ColumnsType } from "antd/es/table";
import BankStatementIcon from "./../../assets/icons/bank-statement-icon.png";
import CibilScoreIcon from "./../../assets/icons/cibil-score-icon.png";
import EMILoanIcon from "./../../assets/icons/emi-loan-icon.png";
import { DrawdownDetailsReducer, DrawdownReducer, ExistingLoan, ExistingLoanRequest, MainReducer, OnboardReducer } from "../../types";
import { getExistingLoans, sendNotification, updateBulk, updateBulkAppraised, getCibilModelScore } from "../../utitlities/request";
import { useLocation } from "react-router-dom";
import { fetchLoan, fetchLoanRequest, updateRequest } from "../../state/action-creators";
import { AppraisalActualStatus, OperationsStatus, notificationTemplates } from "../../utitlities/status";
import moment from "moment";
import { existing_loan_columns } from '../../utitlities/tableColumns';
import { HighlightOutlined } from "@ant-design/icons";
import { validateStatus } from "../../utitlities/validations";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { size } from "lodash";
import '../../styles/buttons.css';

interface Existing_Loan extends ExistingLoan, ExistingLoanRequest {
}

const PersonalDetails: React.FC = () => {
  const history = useHistory();
  const { Text } = Typography;
  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const loanDetails = useSelector((state: MainReducer) => {
    return state.loan.loan.body;
  });
  const loan_req = useSelector((state: MainReducer) => {
    return state.loan.loanRequest.body;
  });
  const onboard_req = useSelector((state: OnboardReducer) => {
    return state.onboarddetails.onboarddetails.body;
  });
  const drawdown_req = useSelector((state: DrawdownDetailsReducer) => {
    return state.drawdowndetails.drawdowndetails.body;
  });
  function isValidDate(date: string): boolean {
    const parsedDate = new Date(date);
    return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
  }

  const period_start_date = isValidDate(employee?.bank_statement?.start_date) ? moment(employee?.bank_statement?.start_date).format('Do MMM YYYY') : "NA";
  const period_end_date = isValidDate(employee?.bank_statement?.end_date) ? moment(employee?.bank_statement?.end_date).format('Do MMM YYYY') : "NA";
  //Update Status Button
  const dispatch = useDispatch();
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");

  const statusList = type === 'appraisal' ? AppraisalActualStatus : OperationsStatus;
  const notifList = notificationTemplates
  const options = [...statusList]?.map(
    (element: any) => {
      return ({
        value: element,
        label: element
      });
    }
  )

  const notifOptions = [...notifList]?.map(
    (element: any) => {
      return ({
        value: element,
        label: element
      });
    }
  )


  const loanId = new URLSearchParams(search).get("loan_id");
  const requestId = new URLSearchParams(search).get("request_id");
  const [open, setOpen] = useState(false);
  const [notifopen, setNotificationOpen] = useState(false);
  const [queryModal, setqueryModal] = useState(false);
  const [selectorValue, setSelectorValue] = useState("");
  const [notifSelectorValue, setNotifSelectorValue] = useState("");
  const [statusComment, setStatusComment] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [okText, setOkText] = useState("Update");
  const [okNotifText, setOkNotifText] = useState("Send");
  const [predictedScoreCibil, setpredictedScoreCibil] = useState("Not Generated");
  const [predictedScoreEquifax, setpredictedScoreEquifax] = useState("Not Generated");


  const showModal = () => {
    setOpen(true);
  }

  const showNotificationModal = () => {
    setOkNotifText("Send")
    setNotificationOpen(true);
  }

  const handleChange = (value: any) => {
    setSelectorValue(value);
  }

  const handleNotifChange = (value: any) => {
    setOkNotifText("Send")
    setNotifSelectorValue(value);
  }

  const handleModalOk = async () => {
    setConfirmLoading(true);
    // const { isValid, errorMessages = [] } = await validateStatus(selectorValue,{employee, loan_req});
    // if (!isValid) {
    //   errorMessages.forEach(errorMessage => {
    //     toast.error(errorMessage, {
    //       position: toast.POSITION.TOP_CENTER,
    //       autoClose: false,
    //       closeOnClick: false,
    //     });
    //   });
    //   return;
    // }
    console.log('Invoking status change API')
    let resp;
    const loanIds = type === 'appraisal' ? [loanId] : [requestId];
    const data = {
      loan_ids: loanIds,
      status: selectorValue,
      request_status_comment: statusComment
    };
    try {
      if (type === 'appraisal') {
        resp = await updateBulkAppraised(data);
      } else {
        resp = await updateBulk(data);
      }

      if (resp !== null) {
        await dispatch(fetchLoan(Number(loanId)));
        await dispatch(fetchLoanRequest(Number(requestId)));
        setOkText("Success")
      } else {
        setOkText("Failed")
      }
      setConfirmLoading(false)
    } catch (err) {
      //TODO: Implement error handling
    }
  }

  const handleNotifModalOk = async () => {
    setConfirmLoading(true);
    let mapping: any = {
      "Enach Pending": ["enach_pending", []],
      "Esign Pending": ["esign_pending", []],
      "Document Pending": ["doc_pending", []]
    }
    console.log('Invoking status change API notif', notifSelectorValue)
    try {
      await sendNotification(employee.id, mapping[notifSelectorValue][0], mapping[notifSelectorValue][1])
      setOkNotifText("Success")
      setConfirmLoading(false)
    } catch (err) {
      //TODO: Implement error handling
    }
  }
  const handleCancel = () => {
    setOkText("Update");
    setConfirmLoading(false)
    setOpen(false);
  }

  const handleNotifCancel = () => {
    setOkText("Send");
    setNotificationOpen(false);
  }

  let [existingLoansData, setExistingLoansData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  async function getExistingLoansData(empId: any) {
    setIsLoading(true);
    try {
      const existingLoansResponse = await getExistingLoans(empId);
      const newLoansData = existingLoansResponse?.data.map((loan: any) => ({
        key: loan.id,
        ops_id: loan.ops_id,
        amount: loan.amount,
        nbfc_loan_id: loan.nbfc_loan_id || "Unavailable",
        loan_status: loan.request_status,
        category: loan.category,
        dpd: loan.DPD_bucket,
        salary_deduction: loan.salary_deduction_ratio,
        channel_Cashfree: loan.channel_Cashfree,
        channel_Salary_deduction: loan.channel_Salary_deduction,
        channel_eNach: loan.channel_eNach,
        no_of_emi: loan.no_of_emi,
        nbfc_status: loan?.nbfc_status
      }));
      setExistingLoansData(newLoansData);
    } finally {
      setIsLoading(false);
    }
  }
  async function getCibilScore(empId: any) {
    try {
      const predictedScore = await getCibilModelScore(empId)
      if (predictedScore.data && predictedScore.data.length) {
        setpredictedScoreCibil(predictedScore.data[0].predicted_loan_category);
      }
      if (predictedScore.equifax_data && predictedScore.equifax_data.length) {
        setpredictedScoreEquifax(predictedScore.equifax_data[0].predicted_loan_category);
      }
      // console.log('cibil score', predictedScoreCibil)
    } catch (error) {
      console.log('error fetching cibil score', error)
    }
  }
  useEffect(() => {
    if (employee.id) {
      getExistingLoansData(employee.id);
      getCibilScore(employee.id)
    }
    // if (loanId) {
    //   getCibilScore(loanId)
    // }
  }, [employee, loanId])

  function capitalizeLabel(label: any) {
    return label.replace(/_/g, ' ').replace(/\b\w/g, (firstChar: string) => firstChar.toUpperCase());
  }
  const moveToQuery = async function () {
    const data = {
      loan_ids: [loanId],
      status: 'CA_Query',
      request_status_comment: 'Loan Moved to CA Query'
    };
    let resp;
    try {
      resp = await updateBulkAppraised(data);

      if (resp !== null) {
        message.success('Loan moved back to Operations');
        history.push('/appraisal');

      } else {
        message.error('Failed to update');
      }
    } catch (err) {
      //TODO: Implement error handling
      message.error('Failed to update');
    }
  }

  return (
    <>
      <ToastContainer />
      <div style={{ height: '100%', overflow: 'hidden' }}>
        <Card bordered={false} className="personal-detail-card">
          <div className="header01">
            <div className="title-action">
              <div className="title01">{employee.first_name + ' ' + employee.last_name}</div>
              <div className="request-id">
                {type === 'onboarding' && (
                  <>
                    <span className="label01">Onboarding ID:</span> {onboard_req.ops_id}
                  </>
                )}

                {type === 'drawdown' ? (
                  <>
                    <span className="label01">Onboarding ID:</span> {onboard_req.ops_id}
                    <span className="label01">Drawdown ID:</span> {drawdown_req.ops_id}
                  </>
                ) : type !== 'onboarding' && (
                  <>
                    <span className="label01">Request ID:</span> {loan_req.ops_id}
                  </>
                )}

                {type === 'appraisal' && (
                  <>
                    <span className="label01">Loan ID:</span> {loanDetails.ops_id}
                  </>
                )}


              </div>
              {type != "drawdown" &&
                <div className="request-id">
                  <span className="label01">Requested Amount:</span> {loan_req.amount_requested}
                  <span className="label01">Suggested Amount: <Text
                    style={{ fontSize: "14px", display: "inline-block" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: (val) => {
                        dispatch(updateRequest({
                          ...loan_req,
                          suggested_amount_requested: val
                        }))
                      },
                    }}>
                    {loan_req.suggested_amount_requested}
                  </Text></span>

                </div>
              }
              <div className="request-id">
                <span className="label01">Mobile No:</span> {employee?.mobile}
                <span className="label01">Request Created at:</span> {moment(loan_req.created_at).format('DD/MM/YYYY')}
              </div>
              <div className="request-id">
                <span className="label01">Whatsapp No:</span> {employee?.whatsapp_no}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: '20px' }}>
              <Button
                type="primary"
                className="update-action-select antd-button"
                defaultValue="Update Status"
                size={"middle"}
                style={{
                  marginBottom: '10px',
                  fontSize: '16px',
                  backgroundColor: "#1B875A",
                  border: 0,
                }}
                onClick={showModal}
                disabled={type == "drawdown" || type == "onboarding"}
              >
                Update Action
              </Button>

              <Button
                type="primary"
                className="update-action-select antd-button"
                defaultValue="Update Status"
                size={"middle"}
                style={{ marginBottom: '10px', fontSize: '16px', backgroundColor: "#1B875A", border: 0 }}
                onClick={showNotificationModal}
                disabled={type == "drawdown" || type == "onboarding"}
              >
                Send Notifications
              </Button>
              {type === 'appraisal' &&
                <>
                  <Button
                    type="primary"
                    className="update-action-select antd-button"
                    defaultValue="Update Status"
                    size={"middle"}
                    style={{ fontSize: '16px', backgroundColor: "#1B875A", border: 0 }}
                    onClick={() => setqueryModal(true)}
                  >
                    CA Query
                  </Button>
                  <Modal
                    title="Alert"
                    open={queryModal}
                    onOk={() => {
                      moveToQuery();
                      setqueryModal(false);
                    }}
                    onCancel={() => {
                      setqueryModal(false);
                    }}
                  >
                    <p>This action is going to move this request back to Operations. Do you wish to proceed ?</p>
                  </Modal>
                </>
              }


              <Modal
                title="Send Notification"
                okText={okNotifText}
                cancelText='Close'
                open={notifopen}
                onOk={() => {
                  if (notifSelectorValue !== "") handleNotifModalOk();
                }}
                bodyStyle={{ alignItems: "center" }}
                confirmLoading={confirmLoading}
                onCancel={handleNotifCancel}
              >

                <Select
                  className="update-action-select"
                  showSearch
                  placeholder="Notification Types"
                  onChange={handleNotifChange}
                  style={{ width: 250 }}
                  options={notifOptions}

                />
              </Modal>

              <Modal
                title="Update Request Status"
                okText={okText}
                cancelText='Close'
                open={open}
                onOk={() => {
                  if (selectorValue !== "") handleModalOk();
                }}
                bodyStyle={{ alignItems: "center" }}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okButtonProps={{
                  disabled: loan_req.request_status == selectorValue || selectorValue === "" || confirmLoading
                }}
              >



                <Select
                  className="update-action-select"
                  showSearch
                  placeholder="Update Actions"
                  onChange={handleChange}
                  style={{ width: 250 }}
                  options={options}

                />

                <Input
                  type="text"
                  placeholder="comment"
                  style={{ width: 250 }}
                  value={statusComment}
                  onChange={(e) => {
                    setStatusComment(e.target.value);
                  }}
                ></Input>

              </Modal>
            </div>
          </div>
          <div className="status-bar">
            <div
              className={type === 'appraisal' ? '' : 'personal-detail-tags-container'}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <Tag style={{ fontSize: "12px", display: "inline-block" }} color="red">
                  {loan_req.request_status || "N/A"}
                </Tag>
                <Tag style={{ fontSize: "12px", display: "inline-block" }} color="red">
                  {`Enach ${loanDetails?.enach?.status || 'Not Generated'}`}
                </Tag>
              </div>
              <div>
                <Tag style={{ fontSize: "12px", display: "inline-block" }} color="red">
                  {`Esign ${loanDetails?.esign?.status || 'Not Generated'}`}
                </Tag>
              </div>
            </div>

            {type == 'appraisal' &&
              <><Card
                className="personal-detail-card"
                style={{
                  marginRight: "20px",
                  width: "200px",
                  height: "80px",
                  borderRadius: "2px",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    overflow: "hidden"
                  }}
                >
                  <p className="personal-detail-card-title" style={{ margin: 0 }}>
                    {predictedScoreEquifax}
                  </p>
                  <p className="personal-detail-card-subtitle" style={{ margin: 0 }}>
                    Entitled Score (Equifax)
                  </p>
                </div>


              </Card><Card
                className="personal-detail-card"
                style={{
                  marginRight: "20px",
                  width: "200px",
                  height: "80px",
                  borderRadius: "2px",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box"
                }}
              >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      overflow: "hidden"
                    }}
                  >
                    <p className="personal-detail-card-title" style={{ margin: 0 }}>
                      {predictedScoreCibil}
                    </p>
                    <p className="personal-detail-card-subtitle" style={{ margin: 0 }}>
                      Entitled Score
                    </p>
                  </div>


                </Card></>




            }

          </div>
          <Divider style={{ borderColor: "#D8D8D8" }} />
          <div className="overview-section">
            <div className="text">Overview</div>
            <div className="overview01">
              <div className="row">
                <div className="section02 bank-analysis-section">
                  <div className="title-section">
                    <img src={BankStatementIcon} alt="bank-statement" />
                    <div className="title02">Bank Statement Analysis</div>
                  </div>
                  <div className="list-section">
                    <div className="list01">
                      <div className="label01">{period_start_date} - {period_end_date}</div>
                      <div className="value01">Period</div>
                    </div>
                    <div className="list01">
                      <div className="label01">{employee?.bank_statement?.existing_emi}</div>
                      <div className="value01">Existing EMI</div>
                    </div>
                    <div className="list01">
                      <div className="label01">{employee?.bank_statement?.abb}</div>
                      <div className="value01">ABB</div>
                    </div>
                    <div className="list01">
                      <div className="label01">&#x20b9; {employee?.bank_statement?.bounced_charges_amount}</div>
                      <div className="value01">Bounce Charge Amount</div>
                    </div>
                    <div className="list01">
                      <div className="label01">&#x20b9; {employee?.bank_statement?.bounced_charges_count}</div>
                      <div className="value01">Bounce Charge Count</div>
                    </div>
                    <div className="list01">
                      <div className="label01">&#x20b9; {employee?.bank_statement?.bounced_emi_count}</div>
                      <div className="value01">Bounce EMI Count</div>
                    </div>
                  </div>
                </div>
                <div className="section02 cibil-score-section">
                  <div className="title-section">
                    <img src={CibilScoreIcon} alt="cibil-statement" />
                    <div className="title02">CIBIL</div>
                  </div>
                  <div className="list-section" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <div className="list02">
                      <div className="label01">Score</div>
                      <div className="value01">{employee?.cibil?.score}</div>
                    </div>
                    <div className="list02">
                      <div className="label01">{"FOIR"}</div>
                      <div className="value01">{employee?.cibil?.foir}</div>
                    </div>
                    <div className="list02">
                      <div className="label01">No. of Recent Enquiries</div>
                      <div className="value01">{employee?.cibil?.no_of_enquires}</div>
                    </div>
                    {/* Check if cibil_suggestion is available */}
                    {employee?.cibil?.cibil_suggestion !== "NA" && (
                      // Dynamically render cibil_suggestion fields
                      Object.entries(employee?.cibil?.cibil_suggestion || {})
                        .filter(([key]) => key !== 'credit_score' && key !== 'emp_id' && key !== 'loan_id')
                        .map(([key, value]) => (
                          <div className="list02" key={key}>
                            <div className="label01">{capitalizeLabel(key)}</div>
                            <div className="value01">{String(value)}</div>
                          </div>
                        ))
                    )}
                    {/* Render a message if cibil_suggestion is "NA" */}
                    {employee?.cibil?.cibil_suggestion === "NA" && (
                      <div className="list02">
                        <div className="label01">CIBIL Suggestion</div>
                        <div className="value01">N/A</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="section02 cibil-score-section">
                  <div className="title-section">
                    <img src={CibilScoreIcon} alt="cibil-statement" />
                    <div className="title02">EQUIFAX</div>
                  </div>
                  <div className="list-section" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <div className="list02">
                      <div className="label01">Score</div>
                      <div className="value01">{employee?.equifax?.score}</div>
                    </div>
                    <div className="list02">
                      <div className="label01">{"FOIR"}</div>
                      <div className="value01">{employee?.equifax?.foir}</div>
                    </div>
                    {/* <div className="list02">
                      <div className="label01">No. of Recent Enquiries</div>
                      <div className="value01">{employee?.cibil?.no_of_enquires}</div>
                    </div> */}
                    {/* Check if cibil_suggestion is available */}
                    {employee?.equifax?.equifax_suggestion !== "NA" && (
                      // Dynamically render cibil_suggestion fields
                      Object.entries(employee?.equifax?.equifax_suggestion || {})
                        .filter(([key]) => key !== 'credit_score' && key !== 'emp_id' && key !== 'request_id')
                        .map(([key, value]) => (
                          <div className="list02" key={key}>
                            <div className="label01">{capitalizeLabel(key)}</div>
                            <div className="value01">{String(value)}</div>
                          </div>
                        ))
                    )}
                    {/* Render a message if cibil_suggestion is "NA" */}
                    {employee?.cibil?.cibil_suggestion === "NA" && (
                      <div className="list02">
                        <div className="label01">CIBIL Suggestion</div>
                        <div className="value01">N/A</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="section02 emi-loan-section" style={{ overflowY: 'auto' }}>
                <div className="title-section">
                  <img src={EMILoanIcon} alt="bank-statement" />
                  <div className="title02">Existing EMI Loan Deductions</div>
                </div>
                <Spin spinning={isLoading}>
                  <Table
                    rowKey={"key"}
                    columns={existing_loan_columns}
                    dataSource={existingLoansData}
                    pagination={false}
                    bordered={false}
                    style={{ width: '100%' }}
                    size="small"
                  />
                </Spin>
              </div>
            </div>
          </div>
        </Card >
      </div>
    </>
  );
};
export default PersonalDetails;
