import { Layout, Menu, Breadcrumb, Row, Col, Tooltip } from "antd";
import Entitled from "../assets/entitled.svg";
import ELogo from "../assets/e.svg"
import MasterViewIcon from "../assets/icons/masterview.svg";
import AppraisalIcon from "../assets/icons/appraisal.svg";
import OperationsIcon from "../assets/icons/operations.svg";
import OriginatedIcon from "../assets/icons/originated.svg";
import * as _ from "lodash";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  LoginOutlined,
  LogoutOutlined,
  DashboardTwoTone,
  DashboardFilled,
  DashboardOutlined,
  BankOutlined,
  LayoutOutlined,
} from "@ant-design/icons";
import {
  useHistory,
  Link,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Originated from "./originated";
import { Employees } from "./employees";
import { Demo } from "./login";
import { useEffect, useLayoutEffect, useState } from "react";
import OpsReview from "./opsReview";
import { TopHeader } from "./common/topHeader";
import Appraisal from "./appraisal";
import IncomingRequest from "./lineOfCredit/incomingRequest";
import LineOfCredit from "./lineOfCredit/lineOfCredit";
import Drawdowns from "./lineOfCredit/drawdowns";
import { LocDetails } from "./lineOfCredit/locDetails";
import { NoCredentials } from "./common/noCredentials";
import Repayment from "./repayment";
import MasterView from "./masterView";
import { RepaymentDetail } from "./repaymentDetail";
import AppraisalDetail from "./AppraisalDetail/AppraisalDetail";
import "./AppraisalDetail/style.scss";
import Onboarding from "./Onboarding";
import Drawdown from "./Drawdown";
import DrawdownDetail from "./AppraisalDetail/DrawdownDetail";
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { innerWidth: width, innerHeight: height } = window;

export function Sidebar() {
  const [role, setRole] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [collapsed, setCollapsed] = useState(false);
  const setSize = () => {
    let path = window.location.pathname;
    switch (path) {
      case "/loans":
        return ["1"];
      case "/ops_review":
        return ["2"];
      case "/appraisal":
        return ["3"];
      case "/loc_request":
        return ["4"];
      case "/loc":
        return ["5"];
      case "/loc_drawdowns":
        return ["6"];
      case "/repayment":
        return ["7"];
      case "/masterview":
        return ["8"];
      case "/tracker":
        return ["9"];
    }
  };
  const [selectedKey, setKey] = useState<any>(setSize());

  useEffect(() => {
    //@ts-ignore
    let user = JSON.parse(localStorage.getItem("user")) || " ";
    setRole(user["role"]);
  }, []);

  function updateDimension() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  function onCollapse() {
    setCollapsed(!collapsed);
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);
  const pathname = window.location.pathname;
  const siderStyle = { width: width * 0.1, height: height, position: 'fixed' as 'fixed', backgroundColor: "#2F3E46" };
  const contentStyle = {
    width: pathname !== "/login" ? width * 0.9 : width, marginLeft: (pathname !== "/login" && pathname !== "/no_cred") ? (collapsed) ? '80px' : '190px' : '0px',
    height: height * 1.1, overFlowX: "hidden"
  };

  return (
    <Router>
      {pathname !== "/login" && pathname !== "/no_cred" ? (
        <>
          <Sider
            style={siderStyle}
            className="site-layout-background custom-sider"
            collapsible collapsed={collapsed} onCollapse={onCollapse}
          >
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 500,
                color: "#fff",
                background: "#2F3E46",
                height: "50px",
                lineHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                paddingLeft: '24px',
                marginTop: '10px'
              }}
            >
              <img
                src={collapsed ? ELogo : Entitled}
                alt="Logo"
                style={{
                  width: collapsed ? '30px' : '70%',
                  height: collapsed ? '30px' : '70%',
                  transition: 'all 0.3s',
                }}
              />
            </div>
            <Menu
              mode="inline"
              defaultSelectedKeys={selectedKey}
              defaultOpenKeys={["sub1", "sub2"]}
              style={{ height: "100%", borderRight: 0, backgroundColor: "#2F3E46" }}
              theme="dark"
            >
              <SubMenu key="sub1" icon={<BankOutlined />} title="Loans" style={{ background: "#2F3E46", marginTop: '20px' }}>
                {
                  !_.includes(['collection', 'agency'], role) ? (
                    <>
                      <Menu.Item key="1" className="custom-menu-item">
                        <Link to="/loans" className="custom-menu-link">
                        <img
                          src={OriginatedIcon}
                          alt="Logo"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                        Originated</Link>
                      </Menu.Item>
                    </>
                  ) : ("")
                }

                {role === "operations" ||
                  role === "super_admin" ||
                  role === "appraisal" ||
                  role === "admin" ? (
                  <>
                    <Menu.Item key="2" className="custom-menu-item">
                      <Link to="/ops_review" className="custom-menu-link">
                        <img
                          src={OperationsIcon}
                          alt="Logo"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                        Operations</Link>
                    </Menu.Item>
                  </>
                ) : (
                  ""
                )}

                {role === "doc_check" ||
                  role === "super_admin" ||
                  role === "appraisal" ||
                  role === "admin" ||
                  role === "operations" ? (
                  <>
                    <Menu.Item key="3" className="custom-menu-item">
                      <Link to="/appraisal" className="custom-menu-link">
                      <img
                          src={AppraisalIcon}
                          alt="Logo"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                        Appraisal</Link>
                    </Menu.Item>
                  </>
                ) : (
                  ""
                )}
                {/* <Menu.Item key="7">
                  <Link to="/repayment">Repayments</Link>
                </Menu.Item> */}
                {_.includes(["collection", "agency", "super_admin", "appraisal", "admin", "operations"], role) ? (
                  <>
                    <Menu.Item key="8" className="custom-menu-item">
                      <Link to="/masterview" className="custom-menu-link">
                      <img
                          src={MasterViewIcon}
                          alt="Logo"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                        Master View</Link>
                    </Menu.Item>
                  </>
                ) : (
                  ""
                )}
                {_.includes(["super_admin"], role) ? (
                  <>
                    <Menu.Item key="9" className="custom-menu-item">
                      <Link to="/tracker" className="custom-menu-link">
                      <img
                          src={OperationsIcon}
                          alt="Logo"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                        Tracker</Link>
                    </Menu.Item>
                  </>
                ) : (
                  ""
                )}
              </SubMenu>
              <SubMenu key="sub2" icon={<BankOutlined />} title="EWA" style={{ background: "#2F3E46", marginTop: '20px' }}>
                {
                  !_.includes(['collection', 'agency'], role) ? (
                    <>
                      <Menu.Item key="10" className="custom-menu-item">
                        <Link to="/ewaOnboarding" className="custom-menu-link">
                        <img
                          src={OriginatedIcon}
                          alt="Logo"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                        Onboardings</Link>
                      </Menu.Item>
                    </>
                  ) : ("")
                }

                {role === "operations" ||
                  role === "super_admin" ||
                  role === "appraisal" ||
                  role === "admin" ? (
                  <>
                    <Menu.Item key="11" className="custom-menu-item">
                      <Link to="/ewaDrawdown" className="custom-menu-link">
                        <img
                          src={OperationsIcon}
                          alt="Logo"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px',
                          }}
                        />
                        Drawdowns</Link>
                    </Menu.Item>
                  </>
                ) : (
                  ""
                )}
              </SubMenu>
            </Menu>
          </Sider>
        </>
      ) : (
        ""
      )}

      <Content
        className={collapsed ? 'content-collapsed' : 'content-expanded'}
        style={contentStyle}
      >
        {!pathname.includes("login") ? <TopHeader /> : null}

        <Switch>
          <Route exact path="/loans">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <Originated></Originated>
            )}
          </Route>
          <Route exact path="/employees">
            <Employees></Employees>
          </Route>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <Demo></Demo>
          </Route>
          <Route exact path="/ops_review">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <OpsReview></OpsReview>
            )}
          </Route>

          <Route exact path="/appraisal">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <Appraisal></Appraisal>
            )}
          </Route>

          <Route exact path="/repayment">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <Repayment></Repayment>
            )}
          </Route>
          <Route exact path="/masterview">
            <MasterView></MasterView>
          </Route>
          {/* <Route exact path="/loc_request">
            <IncomingRequest />
          </Route>
          <Route exact path="/loc">
            <LineOfCredit />
          </Route>
          <Route exact path="/loc_drawdown">
            <Drawdowns />
          </Route>
          <Route exact path="/loc_details">
            <LocDetails />
          </Route> */}
          <Route exact path="/no_cred">
            <NoCredentials />
          </Route>
          <Route exact path="/repayments">
            <RepaymentDetail />
          </Route>
          <Route exact path="/tracker">
            {role && _.includes(['super_admin'], role) ? (
              <iframe
                src="https://metabase.entitled.co.in/public/dashboard/1ddc29fa-333b-4aaa-ab57-36b49ca8a4a9"
                width="1500"
                height="1000"
                allowTransparency>
              </iframe>
            ) : (
              <Redirect to="/no_cred" />
            )}
          </Route>
          <Route exact path="/appraisal-detail">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <AppraisalDetail />
            )}
          </Route>
          <Route exact path="/drawdown-detail">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <DrawdownDetail />
            )}
          </Route>
          <Route exact path="/ewaOnboarding">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <Onboarding />
            )}
          </Route>

          <Route exact path="/ewaDrawdown">
            {role && _.includes(['collection', 'agency'], role) ? (
              <Redirect to="/no_cred" />
            ) : (
              <Drawdown />
            )}
          </Route>
        </Switch>
      </Content>
    </Router>
  );
}
