
import { Dispatch } from "redux"
import {
    employeeDetails, getLoanComments, getLoanRequest, getOriginalLoanData, getRepaymentsDetail, getStatusLogs, nbfc, getCibil,getActivityStream,
    getOperationsListApiCall, getAppraisalListApiCall, getMasterViewListApiCall, employeeSave, updateLoanRequest,
    getDrawdownListApiCall,
    getOnboardListApiCall,
    getOnboardingDetails,
    getDrawdownDetails
} from "../../utitlities/request"
import { ActionType } from "../action-types"
import { Action } from "../actions/index"

export const fetchOnboardRequest = (onboard_id: number) => async (dispatch: Dispatch<Action>) => {
    const response = await getOnboardingDetails(onboard_id);
    return dispatch({
        type: ActionType.FETCHONBOARDREQUEST,
        payload: response
    })
}
export const fetchDrawdownRequest = (onboard_id: number) => async (dispatch: Dispatch<Action>) => {
    const response = await getDrawdownDetails(onboard_id);
    return dispatch({
        type: ActionType.FETCHDRAWDOWN,
        payload: response
    })
}
export const fetchLoanRequest = (loan_request_id: number) => async (dispatch: Dispatch<Action>) => {
    const response = await getLoanRequest(loan_request_id);
    return dispatch({
        type: ActionType.FETCHLOANREQUEST,
        payload: response
    })
}
export const fetchEmployee = (employee_id: any) => async (dispatch: Dispatch<Action>) => {
    const response = await employeeDetails(employee_id);
    return dispatch({
        type: ActionType.FETCHEMPLOYEE,
        payload: response
    })
}

export const clearEmployeeData = () => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: ActionType.CLEAR_EMPLOYEE_DATA,
    })
};

export const updateEmployee = (employee: any, flattened = {}) => async (dispatch: Dispatch<Action>) => {
    let data = {
        first_name: employee.first_name,
        last_name: employee.last_name,
        email_id: employee.email_id,
        dob: employee.dob,
        father_name: employee.father_name,
        gender: employee.gender,
        marital_status: employee.marital_status,
        pan: employee.pan_no,
        aadhaar: employee.aadhaar,
        whatsapp_no: employee.whatsapp_no,
        // contact_no: mobile,
        contact_no_2: employee.mobile_2,
        address: employee.address,
        state: employee.state,
        city: employee.city,
        pincode: employee.pincode,
        address_1: employee.address_1,
        pincode_1: employee.pincode_1,
        state_1: employee.state_1,
        city_1: employee.city_1,
        ifsc_code: employee.bank_details.ifsc_code,
        account_no: employee.bank_details.account_no,
        bank_statement_password: employee.bank_details.bank_statement_password,
        beneficiary_name: employee.bank_details.beneficiary_name,
        vpa:employee.bank_details.vpa,
    };

    const response = await employeeSave(data, employee.id);


    return dispatch({
        type: ActionType.UPDATEEMPLOYEE,
        payload: employee
    })
}



export const updateRequest = (loan_request: any) => async (dispatch: Dispatch<Action>) => {
    const response = await updateLoanRequest(loan_request.id, loan_request);
    return dispatch({
        type: ActionType.UPDATELOANREQUEST,
        payload: loan_request
    })
}

export const fetchLoan = (loan_id: number) => async (dispatch: Dispatch<Action>) => {
    const response = await getOriginalLoanData(loan_id);
    return dispatch({
        type: ActionType.FETCHLOAN,
        payload: response
    })
}

export const setOperationsApiParams = (params: any) => async (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: ActionType.SET_OPERATIONS_API_PARAMS,
        payload: params
    })
}
export const getOperationsList = (request: any) => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: ActionType.GET_OPERATIONS_LIST_API_LOADING,
        payload: true
    })
    const response = await getOperationsListApiCall(request);
    dispatch({
        type: ActionType.GET_OPERATIONS_LIST_API_LOADING,
        payload: false
    })
    return dispatch({
        type: ActionType.SET_OPERATIONS_LIST,
        payload: response
    })
}

export const fetchNbfc = () => async (dispatch: Dispatch<Action>) => {
    const response = await nbfc();
    return dispatch({
        type: ActionType.FETCHNBFC,
        payload: response
    })
}

export const fetchComments = (request_id: any) => async (dispatch: Dispatch<Action>) => {
    const response = await getLoanComments(request_id)
    return dispatch({
        type: ActionType.FETCHCOMMENTS,
        payload: response
    })
}

export const fetchLogs = (request_id: any) => async (dispatch: Dispatch<Action>) => {
    const response = await getStatusLogs(request_id)
    return dispatch({
        type: ActionType.FETCHLOGS,
        payload: response
    })
}


export const fetchStream = (entity_type:any, entity_id:any) => async (dispatch: Dispatch<Action>) => {
    const response = await getActivityStream(entity_type, entity_id)
    return dispatch({
        type: ActionType.FETCHSTREAM,
        payload: response
    })
}

export const fetchRepayment = (request_id: any) => async (dispatch: Dispatch<Action>) => {
    const response = await getRepaymentsDetail(request_id)
    return dispatch({
        type: ActionType.FECTHREPAYMENT,
        payload: response
    })
}

export const fetchArthmate = (loan_id: any) => async (dispatch: Dispatch<Action>) => {
    const response = await getCibil(loan_id)
    return dispatch({
        type: ActionType.FETCHARTHMATE,
        payload: response
    })
}

export const reset = () => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: ActionType.RESET,
        payload: {}
    })
}

export const setAppraisalApiParams = (params: any) => async (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: ActionType.SET_APPRAISAL_API_PARAMS,
        payload: params
    })
}
export const getAppraisalList = (request: any) => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: ActionType.GET_APPRAISAL_LIST_API_LOADING,
        payload: true
    })
    const response = await getAppraisalListApiCall(request);
    dispatch({
        type: ActionType.GET_APPRAISAL_LIST_API_LOADING,
        payload: false
    })
    return dispatch({
        type: ActionType.SET_APPRAISAL_LIST,
        payload: response
    })
}
export const setDrawdownApiParams = (params: any) => async (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: ActionType.SET_DRAWDOWN_API_PARAMS,
        payload: params
    })
}
export const getDrawdownList = (request: any) => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: ActionType.GET_DRAWDOWN_LIST_API_LOADING,
        payload: true
    })
    const response = await getDrawdownListApiCall(request);
    dispatch({
        type: ActionType.GET_DRAWDOWN_LIST_API_LOADING,
        payload: false
    })
    return dispatch({
        type: ActionType.SET_DRAWDOWN_LIST,
        payload: response
    })
}
export const setOnboardApiParams = (params: any) => async (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: ActionType.SET_ONBOARDING_API_PARAMS,
        payload: params
    })
}
export const getOnboardList = (request: any) => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: ActionType.GET_ONBOARDING_LIST_API_LOADING,
        payload: true
    })
    const response = await getOnboardListApiCall(request);
    dispatch({
        type: ActionType.GET_ONBOARDING_LIST_API_LOADING,
        payload: false
    })
    return dispatch({
        type: ActionType.SET_ONBOARDING_LIST,
        payload: response
    })
}

export const setMasterViewApiParams = (params: any) => async (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: ActionType.SET_MASTER_VIEW_API_PARAMS,
        payload: params
    })
}
export const getMasterViewList = (request: any) => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: ActionType.GET_MASTER_VIEW_LIST_API_LOADING,
        payload: true
    })
    const response = await getMasterViewListApiCall(request);
    dispatch({
        type: ActionType.GET_MASTER_VIEW_LIST_API_LOADING,
        payload: false
    })
    return dispatch({
        type: ActionType.SET_MASTER_VIEW_LIST,
        payload: response
    })
}
