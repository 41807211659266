export const util = {
  getBankStatus: (params: any): string => {
    if (params.data.is_bank_account_verified && params.data.is_bank_account_verified == 0) {
      return "NOT_CHECKED";
    }
    else if (params.data.is_bank_account_verified && params.data.is_bank_account_verified == 1) {
      return "VALID";
    }
    else if (params.data.is_bank_account_verified && params.data.is_bank_account_verified == 2) {
      return "INVALID";
    }
    else {
      return "NOT_PRESENT";
    }
  },
};