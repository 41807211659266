import { DrawdownDetailsReducer, MainReducer, OnboardReducer } from "../../types";
import { ActionType } from "../action-types/index";
import { Action } from "../actions";
import moment from "moment";

export const operationsApiParamsInitState = {
    page: 1,
    pageSize: 50,
    filterData: {
        whatsapp_no: "",
        employee_id: "",
        status: "",
        start_date: moment().subtract(2, "month").format("YYYY-MM-DD"),
        end_date: moment().add(1, "days").format("YYYY-MM-DD")
    },
    filterModel: null,
    sortModel: null
}
const initialState: DrawdownDetailsReducer = {
    employee: {
        status: false,
        body: {}
    },
    drawdowndetails: {
        status: false,
        body: {},
    },
    // loanRequest: {
    //     status: false,
    //     body: {}
    // },
    // nbfc: {
    //     status: false,
    //     body: {}
    // },
    // comments: {
    //     status: false,
    //     body: {}
    // },
    // logs: {
    //     status: false,
    //     body: {}
    // },
    // stream: {
    //     status: false,
    //     body: {}
    // },
    // repayment: {
    //     status: false,
    //     body: {}
    // },
    // operationsFilter: {},
    // appraisalFilter: {},
    // arthmate: {
    //     status: false,
    //     body: {}
    // },
    // operationsApiParams: { ...operationsApiParamsInitState },
    // operationsApiResponse: {
    //     response: null,
    //     status: false,
    //     loading: false
    // },
};

const reducer = (state: DrawdownDetailsReducer = initialState, action: Action): DrawdownDetailsReducer => {
    switch (action.type) {
        case ActionType.FETCHDRAWDOWN:
            if (action.payload.status && action.payload.status_code === 404) {
                return { ...state, ...{ drawdowndetails: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ drawdowndetails: { body: action.payload, status: true } } };
            }
        case ActionType.FETCHEMPLOYEE:
            if (action.payload.status && action.payload.status_code === 404) {
                return { ...state, ...{ employee: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ employee: { body: action.payload, status: true } } };
            }

        default:
            return state
    }
}

export default reducer