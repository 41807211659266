import { combineReducers } from "redux";
import loanReducer from "./loanReducer";
import appraisalReducers from "./appraisalReducer";
import masterViewReducers from "./masterViewReducer";
import drawdownReducer from "./drawdownReducer"
import onBoardDetailReducer from "./onboardReducer"
import onBoardListReducer from "./onBoardingListReducer"
import drawdownDetailReducer from "./drawdownDetailsReducer"

const reducers = combineReducers({
    loan: loanReducer,
    appraisal: appraisalReducers,
    master: masterViewReducers,
    drawdown: drawdownReducer,
    onboarddetails: onBoardDetailReducer,
    onboarding: onBoardListReducer,
    drawdowndetails: drawdownDetailReducer
})

export default reducers

export type RootState = ReturnType<typeof reducers>